@use 'sass:map';
@use '@material/button/button-protected-theme' as mdc-protected;
@use '@material/button/button-outlined-theme' as mdc-outlined;
@use '@material/button/button-text-theme' as mdc-text;

@mixin buildButtonRules($config) {
  @include buildMaterialButtonRules($config);
  // @include ngMaterialMiniFabButtonOverrides($config);
  // @include ngMaterialIconButtonOverrides($config);
  // @include buildCustomButtons($config);
}

@mixin ngMaterialButtonTraits($font, $overlay: null) {
  font-family: $font;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0781rem;
  text-transform: uppercase;

  &.kt__button__link {
    font-weight: 400;
    letter-spacing: 0.00625rem;
    text-decoration: none;
    text-transform: none;
  }

  & > .mat-icon {
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }
}

@mixin buildMaterialButtonRules($config) {
  @include overrideMaterialBasicButtonStyles($config);
  @include overrideMaterialOutlineButtonStyles($config);
  @include overrideMaterialRaisedButtonStyles($config);
}

@mixin overrideMaterialBasicButtonStyles($config) {
  .mat-mdc-button {
    @include mdc-protected.theme(
      (
        disabled-label-text-color: map.get($config, button-disabled),
        label-text-font: map.get($config, font-secondary),
        label-text-size: 0.875rem,
        label-text-tracking: 0.0781rem,
        label-text-transform: uppercase,
        label-text-weight: 700,
      )
    );
    @include ngMaterialButtonTraits(map.get($config, font-secondary));

    &.mat-primary {
      @include mdc-protected.theme(
        (
          disabled-label-text-color: map.get($config, button-disabled),
          label-text-color: map.get($config, button-brand),
        )
      );
      @include mdc-text.theme(
        (
          label-text-color: map.get($config, button-brand),
        )
      );
    }

    &.mat-accent {
      @include mdc-protected.theme(
        (
          disabled-label-text-color: map.get($config, button-disabled),
          label-text-color: map.get($config, button-brand-emphasis),
        )
      );
      @include mdc-text.theme(
        (
          label-text-color: map.get($config, button-brand-emphasis),
        )
      );
    }

    &.mat-warn {
      @include mdc-protected.theme(
        (
          disabled-label-text-color: map.get($config, button-disabled),
          label-text-color: map.get($config, button-error),
        )
      );
      @include mdc-text.theme(
        (
          label-text-color: map.get($config, button-error),
        )
      );
    }
  }
}

@mixin overrideMaterialRaisedButtonStyles($config) {
  .mat-mdc-raised-button {
    @include mdc-protected.theme(
      (
        disabled-container-color: map.get($config, button-disabled-bg),
        disabled-label-text-color: map.get($config, button-disabled),
        label-text-font: map.get($config, font-secondary),
        label-text-size: 0.875rem,
        label-text-tracking: 0.0781rem,
        label-text-transform: uppercase,
        label-text-weight: 700,
      )
    );
    @include ngMaterialButtonTraits(map.get($config, font-secondary));

    &.mat-primary {
      @include mdc-protected.theme(
        (
          disabled-container-color: map.get($config, button-disabled-bg),
          disabled-label-text-color: map.get($config, button-disabled),
          container-color: map.get($config, button-brand),
          label-text-color: map.get($config, button-brand-contrast),
        )
      );
    }

    &.mat-accent {
      @include mdc-protected.theme(
        (
          disabled-container-color: map.get($config, button-disabled-bg),
          disabled-label-text-color: map.get($config, button-disabled),
        )
      );
    }

    &.mat-warn {
      @include mdc-protected.theme(
        (
          disabled-container-color: map.get($config, button-disabled-bg),
          disabled-label-text-color: map.get($config, button-disabled),
          container-color: map.get($config, button-error),
          label-text-color: map.get($config, button-brand-contrast),
        )
      );
    }
  }
}

@mixin overrideMaterialOutlineButtonStyles($config) {
  .mat-mdc-outlined-button {
    @include mdc-outlined.theme(
      (
        disabled-outline-color: map.get($config, button-disabled),
        disabled-label-text-color: map.get($config, button-disabled),
        label-text-font: map.get($config, font-secondary),
        label-text-size: 0.875rem,
        label-text-tracking: 0.0781rem,
        label-text-transform: uppercase,
        label-text-weight: 700,
      )
    );
    @include ngMaterialButtonTraits(map.get($config, font-secondary));

    &.mat-primary {
      @include mdc-outlined.theme(
        (
          disabled-outline-color: map.get($config, button-disabled),
          disabled-label-text-color: map.get($config, button-disabled),
          outline-color: map.get($config, button-brand),
        )
      );
    }

    &.mat-accent {
      @include mdc-outlined.theme(
        (
          disabled-outline-color: map.get($config, button-disabled),
          disabled-label-text-color: map.get($config, button-disabled),
        )
      );
    }

    &.mat-warn {
      @include mdc-outlined.theme(
        (
          disabled-outline-color: map.get($config, button-disabled),
          disabled-label-text-color: map.get($config, button-disabled),
          outline-color: map.get($config, button-error),
        )
      );
    }
  }
}

// @mixin ngMaterialMiniFabButtonOverrides($config) {
//   // TODO(wayland): Convert all mini-fabs we use to chips. It's what they should be.
//   $primary-palette: map.get($config, primary);
//   $neutral-palette: map.get($config, neutral);

//   .#{utils.$kt-prefix}mini-fab__actions.mat-mdc-mini-fab {
//     background-color: map.get($neutral-palette, 100);
//     color: map.get($neutral-palette, 500);
//     min-width: 2.4rem;
//     width: auto;
//     height: 1.9rem;
//     border: 1px solid map.get($neutral-palette, 500);
//     box-shadow: none;
//     font-family: utils.get_temp($config, font-secondary);

//     // Needed for specificity precedence.
//     &:not(.mdc-fab--extended) {
//       border-radius: 6rem;
//     }

//     &:disabled {
//       background-color: map.get($neutral-palette, 50);
//       color: map.get($neutral-palette, 400);
//       border: 1px solid map.get($neutral-palette, 400);
//     }

//     .mdc-button__label {
//       padding: 0.125rem 0 0;
//       text-wrap: nowrap;
//       vertical-align: top;
//     }

//     // TODO(wayland): Moved here from components. Find a way to get rid of this.
//     .actions__icon {
//       height: 1.25rem;
//       width: 1.25rem;
//       font-size: 1.2rem;
//       vertical-align: middle;
//     }

//     &.active {
//       background-color: map.get($primary-palette, 600);
//       color: map.get($neutral-palette, A100);
//     }
//   }

//   .#{utils.$kt-prefix}assessments-table__action-button.mat-mdc-mini-fab:disabled {
//     color: utils.get_temp($config, button-disabled-bg);
//     border-color: utils.get_temp($config, button-disabled-bg);
//   }
// }

// @mixin ngMaterialIconButtonOverrides($config) {
//   $neutral-palette: map.get($config, neutral);
//   .mat-icon-button.kt__icon-button {
//     .mat-button-wrapper {
//       display: flex;
//       justify-content: center;
//     }

//     background-color: map.get($neutral-palette, 100);
//   }
// }

// @mixin buildCustomButtons($config) {
//   $primary-palette: map.get($config, primary);
//   $neutral-palette: map.get($config, neutral);
//   $error-palette: map.get($config, error);
//   $fonts: map.get($config, fonts);
//   $colors: map.get($config, status-indicator-colors);

//   .#{utils.$kt-prefix}button--primary-accent {
//     color: map.get($primary-palette, A400);
//   }

//   .#{utils.$kt-prefix}link-button {
//     cursor: pointer;
//     outline: 0;
//     border: 0;
//     padding: 0;
//     margin: 0;
//     background-color: transparent;
//     color: utils.get_temp($config, link-text-brand-accent);
//   }

//   //TODO(mikita): used to fix dialog scroll. Remove during material button fixing
//   .kt__stroked-button.mat-stroked-button .mat-button-ripple.mat-ripple,
//   .kt__stroked-button.mat-stroked-button .mat-button-focus-overlay {
//     bottom: 0px;
//     top: 0px;
//   }
// }
