@use 'sass:map';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@material/linear-progress/linear-progress-theme' as mdc-linear-progress-theme;

@use 'src/theme/factory/factory';
@use 'src/theme/factory/buttons';
@use 'src/theme/factory/checkboxes';


@include mat.core();

$kt-themes: factory.definePalettesAndThemes();
$main-theme: map.get($kt-themes, theme);
$config: factory.getConfig();

// Include theme styles for core and each component used in your app.
@include mat.core-theme($main-theme);
@include mat.button-theme(map.get($kt-themes, button-theme));
@include mat.card-theme($main-theme);
@include mat.checkbox-theme($main-theme);
@include mat.form-field-theme(map.get($kt-themes, field-theme));
@include mat.input-theme($main-theme);
@include mat.select-theme($main-theme);

@include buttons.buildButtonRules($config);
@include checkboxes.buildRules($config);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Noto Sans', Roboto, sans-serif;
  --mat-form-field-subscript-text-size: 0.75rem;
}

.kt__snackbar__normal.mat-mdc-snack-bar-container {
  color: #ffffff;
  background-color: #003f87;
}

.kt__snackbar__normal.mat-mdc-snack-bar-container .DefaultToast__Suffix:not(.mat-mdc-button) {
  color: #8a8a8a;
}

.kt__snackbar__dark.mat-mdc-snack-bar-container {
  color: #ffffff;
  background-color: #212121;
}

.kt__snackbar__dark.mat-mdc-snack-bar-container .DefaultToast__Prefix {
  color: #b3b3b3;
}

.kt__snackbar__dark.mat-mdc-snack-bar-container .DefaultToast__Suffix {
  color: #8a8a8a;
}

.kt__snackbar__dark.mat-mdc-snack-bar-container .DefaultToast__Suffix.mat-mdc-button {
  color: #0099ff;
}

.kt__snackbar__error.mat-mdc-snack-bar-container {
  color: #8d0d11;
  background-color: #fde8e8;
}

.kt__snackbar__error.mat-mdc-snack-bar-container .DefaultToast__Prefix,
.kt__snackbar__error.mat-mdc-snack-bar-container .DefaultToast__Suffix.mat-mdc-button {
  color: #eb1c22;
}

.kt__snackbar__light.mat-mdc-snack-bar-container {
  color: #212121;
  background-color: #ffffff;
}

.kt__snackbar__light.mat-mdc-snack-bar-container .DefaultToast__Prefix {
  color: #666666;
}

.kt__snackbar__light.mat-mdc-snack-bar-container .DefaultToast__Suffix {
  color: #757575;
}

.kt__snackbar__light.mat-mdc-snack-bar-container .DefaultToast__Suffix.mat-mdc-button {
  color: #003f87;
}

.kt__snackbar__neutral.mat-mdc-snack-bar-container {
  color: #666666;
  background-color: #e5e5e5;
}

.kt__snackbar__neutral.mat-mdc-snack-bar-container .DefaultToast__Suffix {
  color: #757575;
}

.kt__snackbar__neutral.mat-mdc-snack-bar-container .DefaultToast__Prefix,
.kt__snackbar__neutral.mat-mdc-snack-bar-container
  .DefaultToast__Suffix.mat-mdc-button {
  color: #9e9e9e;
}

.kt__snackbar__success.mat-mdc-snack-bar-container {
  color: #1f6b2e;
  background-color: #e9f3eb;
}

.kt__snackbar__warn.mat-mdc-snack-bar-container {
  color: #af7f1f;
  background-color: #fff5e5;
}

.kt__snackbar__warn.mat-mdc-snack-bar-container .DefaultToast__Prefix {
  color: #fab62d;
}

.mat-mdc-snack-bar-label.mdc-snackbar__label {
  padding-bottom: 0;
  padding-top: 0;
}

.mat-mdc-snack-bar-container {
  border-radius: 0.25rem;
}

.kt__badgeEnvironment--sandbox {
  background-color: #fab62d;
  color: #ffffff;
}

.kt__badgeEnvironment--dev,
.kt__badgeEnvironment--development,
.kt__badgeEnvironment--boo,
.kt__badgeEnvironment--demo,
.kt__badgeEnvironment--infra,
.kt__badgeEnvironment--staging {
  background-color: #bd5b00;
  color: #ffffff;
}

.kt__badgeEnvironment--alpha {
  background-color: #278739;
  color: #ffffff;
}

.kt__badgeEnvironment--beta,
.kt__badgeEnvironment--betaeun {
  background-color: #a64686;
  color: #ffffff;
}

.strength-indicator__strength--weak.mat-mdc-progress-bar {
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: #eb161c,
    )
  );
}

.strength-indicator__strength--fair.mat-mdc-progress-bar {
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: #fab62d,
    )
  );
}

.strength-indicator__strength--good.mat-mdc-progress-bar {
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: #007eb6,
    )
  );
}

.strength-indicator__strength--strong.mat-mdc-progress-bar {
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: #278739,
    )
  );
}

.strength-indicator__strength--progress.mat-progress-bar {
  border-radius: 2rem;
  height: 0.5rem;
}

.strength-indicator__strength--progress
  .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element::after {
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
}

.mat-mdc-checkbox .mdc-form-field {
  display: inline-flex;

  .mdc-checkbox {
    padding: 0;
    margin: 0;
    height: 1rem;
    width: 1rem;

    .mat-mdc-checkbox-touch-target,
    .mdc-checkbox__native-control,
    .mdc-checkbox__background {
      height: 1rem;
      width: 1rem;
    }

    .mdc-checkbox__background {
      top: 0;
      left: 0;
    }
  }

  .mdc-label {
    white-space: nowrap;
  }
}

.kt__progress-bar.mat-mdc-progress-bar {
  @include mdc-linear-progress-theme.theme(
    (
      active-indicator-color: #0099ff,
    )
  );
}
