@use '@material/checkbox/checkbox-theme' as mdc-checkbox;

@mixin buildRules($config) {
  // TODO(wayland): Actually configure the theme for checkboxes. See source code for list of custom
  // properties that can be set for checkboxes.
  // https://github.com/material-components/material-components-web/blob/master/packages/mdc-checkbox/_checkbox-theme.scss#L76
  .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
    @include mdc-checkbox.theme(
      (
        unselected-focus-state-layer-color: transparent,
      )
    );
  }
}